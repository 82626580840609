<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Leave Groups</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Leave Groups</li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <b-row>
                <b-col lg="6" class="mb-1rem">
                    <router-link
                        v-if="hasPermission('CAN_MANAGE_LEAVE_GROUPS')"
                        :to="{name: 'create-leave-group'}"
                        class="btn btn-primary text-right"
                        tag="button"
                    >
                        <font-awesome-icon icon="plus"></font-awesome-icon>&nbsp; Create Leave Group
                    </router-link>
                </b-col>
            </b-row>

            <b-table
                show-empty
                striped
                hover
                :items="items"
                :fields="fields"
                responsive
            >
                <template v-slot:cell(actions)="row">
                    <span class="actions">
                        <router-link
                            v-b-tooltip.hover title="Edit"
                            class="circle"
                            :to="{ name: 'edit-leave-group', params: { id:  row.item.id}}"
                        >
                            <font-awesome-icon
                                v-if="hasPermission('CAN_MANAGE_LEAVE_GROUPS')"
                                icon="pen"
                            ></font-awesome-icon>
                        </router-link>
                        <span class="ml-1 circle" @click="openHistory(row.item)"  v-b-tooltip.hover title="History">
                            <font-awesome-icon
                                icon="history"
                                v-b-modal.modal-center
                                style="cursor: pointer;" 
                            ></font-awesome-icon>
                        </span>
                    </span>
                </template>
                 <template v-slot:cell(credit_frequency)="row">
                     {{str_title(row.item.credit_frequency)}}
                </template>
                 <template v-slot:cell(compoff)="row">
                  <p v-if="row.item.compoff">
                    <span v-if="row.item.compoff.comp_off_holidays">Holidays : {{ row.item.compoff.comp_off_holidays.length > 0 ? str_title(row.item.compoff.comp_off_holidays.join(', ')) : "None"}}</span>
                    <br>
                    <span v-if="row.item.compoff.comp_off_week_offs">Week Offs : {{ row.item.compoff.comp_off_week_offs.length > 0 ? str_title(row.item.compoff.comp_off_week_offs.join(', ')): "None"}}</span>
                </p>
                </template>

            </b-table>

             <!-- History modal -->
             <b-modal 
                :id="history.id" 
                centered  
                size="xl" 
                hide-footer
            >
                <template #modal-title>
                    History
                </template>
                <b-table
                    show-empty
                    striped
                    hover
                    :items="history.rows"
                    :fields="history.fields"
                    no-local-sorting
                    responsive
                >
                  <template v-slot:cell(compoff)="row">
                   <p v-if="row.item.compoff">
                    <span v-if="row.item.compoff.comp_off_holidays">Holidays : {{ row.item.compoff.comp_off_holidays.length > 0 ? str_title(row.item.compoff.comp_off_holidays.join(', ')) : "Null"}}</span>
                    <br>
                    <span v-if="row.item.compoff.comp_off_week_offs">Week Offs : {{ row.item.compoff.comp_off_week_offs.length > 0 ? str_title(row.item.compoff.comp_off_week_offs.join(', ')): "Null"}}</span>
                </p>
                </template>
                </b-table>
            </b-modal>

        </b-container>
    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            api_error: "",
            fields: [
                { key: "name"},
                { key: "credit_frequency" },
                { key: "leaves"},
                { label:"Carry Forward Leaves", key: "leaves_carry_forwarded"},
                { label:"Encash Leaves", key: "leaves_encashed"},
                { label:"Compoff", key: "compoff"},
                { key: 'actions'}
            ],
            items: null,
            filter: null,
            access: true,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            message:"",
            history: {
                id: 'history-modal',
                title: 'History',
                fields: [
                    { key: "name"},
                    { key: "credit_frequency" },
                    { key: "leaves"},
                    { key: "leaves_carry_forwarded", label:"Carry Forward Leaves"},
                    { key: "leaves_encashed", label:'Encash Leaves'},
                    { label: "Updated by",key: "updated_by_name", sortable: false },
                    { key: "compoff"},
                    { key: "updated_at", sortable: false },
                ],
                rows: []
            },
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_MANAGE_LEAVE_GROUPS");
        if (this.$route.query && this.$route.query.query) {
            this.filter = this.$route.query.query;
        }
        this.getLeaveGroups();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        openHistory(item) {
            this.history.rows = (item.history) ? JSON.parse(item.history) : "";
            this.$root.$emit("bv::show::modal", this.history.id);
        },
        
        getLeaveGroups: function() {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query['params']={};
            this.axios.get(this.$api.get_leave_groups, query).then(response => {
                this.items = response.data.data;
                this.api_error = "";
                this.updateProgressBar(true);
            }).catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.items = [];
                this.updateProgressBar(false);
                this.showAlert();
            });
        },
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
</style>
